<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import InputControl from '@/Components/Controls/InputControl.vue';
import Section from '@/Components/Market/Section.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import MarketLayout from '@/Layouts/MarketLayout.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login.post', {}, false), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <MarketLayout title="Войти в профиль">
        <Section title="Войти в профиль" description="Это обязательно для оформления заказа" class="mt-16 text-center w-full">
            <div class="bg-white max-w-md text-left mx-auto p-8">
                <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                    {{ status }}
                </div>

                <form @submit.prevent="submit">
                    <InputControl
                        id="email"
                        type="email"
                        label="Email"
                        v-model="form.email"
                        :error="form.errors.email"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <InputControl 
                        id="password"
                        type="password"
                        label="Пароль"
                        v-model="form.password"
                        :error="form.errors.password"
                        required
                        autofocus
                        autocomplete="password"
                    />

                    <div class="block mt-4">
                        <label class="flex items-center">
                            <Checkbox name="remember" v-model:checked="form.remember" />
                            <span class="ml-2 text-sm text-gray-600">Запомнить меня</span>
                        </label>
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <!-- <Link
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Забыли пароль?
                        </Link> -->

                        <Link
                            :href="route('register', {}, false)"
                            class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Создать аккаунт
                        </Link>

                        <PrimaryButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Войти
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </Section>
    </MarketLayout>
</template>
